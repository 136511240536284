import React from 'react'
import Admin1 from '../img/Team/Magagement-Team/Eipe-Chundamannil.webp';
import Admin2 from '../img/Team/Magagement-Team/Sreekanth-Nair.webp';
import Admin3 from '../img/Team/Magagement-Team/Praveen-Kumar.webp';
import Admin4 from '../img/Team/Magagement-Team/Shaju-K-Francis.webp';
import Admin5 from '../img/Team/Magagement-Team/K-P-Shibu.webp';
import Admin6 from '../img/Team/Magagement-Team/Bindhu-P-Rajan.webp';
import Admin7 from '../img/Team/Magagement-Team/Revothy-Thazhathuveedu.webp';
import Admin8 from '../img/Team/Magagement-Team/Joshy-P-Andrews.webp';
import Admin9 from '../img/Team/Magagement-Team/Sandhya-Subin.webp';
import Admin10 from '../img/Team/Magagement-Team/Rishi-Philip-Oommen.webp';




function MediumPools() {
  return (
    <>
   <div className='range-title'><h3>Management Team</h3></div>
  
    <div className='products-container'>
    <div className='pro-col1'><img src={Admin1} alt="SML Staff" /><div class="blue-bar">Eipe Chundamannil<div className='position'>[Chairperson]</div></div></div>
    <div className='pro-col2'><img src={Admin2} alt="OasiSML Staffs" /><div class="blue-bar">Sreekanth Nair<div className='position'>[Secretary]</div></div></div>
    <div className='pro-col3'><img src={Admin3} alt="SML Staff" /><div class="blue-bar">Praveen Kumar<div className='position'>[Treasurer]</div></div></div>   
    <div className='pro-col4'><img src={Admin4} alt="SML Staff" /><div class="blue-bar">Shaju K Francis<div className='position'>[Principal]</div></div></div>  
    <div className='pro-col5'><img src={Admin5} alt="SML Staff" /><div class="blue-bar">K.P. Shibu<div className='position'>[Vice Chairperson]</div></div></div> 
    <div className='pro-col6'><img src={Admin6} alt="SML Staff" /><div class="blue-bar">Bindu P Rajan<div className='position'>[Vice Principal]</div></div></div> 
    <div className='pro-col7'><img src={Admin7} alt="SML Staff" /><div class="blue-bar">Revothy Thazhathuveedu<div className='position'>[Joint Secretary]</div></div></div> 
    <div className='pro-col8'><img src={Admin8} alt="SML Staff" /><div class="blue-bar">Joshy P Andrews<div className='position'>[Admin Representative]</div></div></div> 
    <div className='pro-col9'><img src={Admin9} alt="SML Staff" /><div class="blue-bar">Sandya Subin<div className='position'>[Teacher Representative]</div></div></div> 
    <div className='pro-col10'><img src={Admin10} alt="SML Staff" /><div class="blue-bar">Rishi Philip Oommen<div className='position'>[P&amp;C Representative]</div></div></div> 
    
  </div>

  </>
 
  )
}

export default MediumPools